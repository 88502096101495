import Vue from 'vue'
import Router from 'vue-router';
Vue.use(Router);

const routes=[
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name:'login',
        component: () => import('../views/login/index'),

    },
    {
        path: '/userCenter',
        name:'userCenter',
        component: () => import('../views/app/userCenter/index'),

    },
    {
        path: '/app',
        name:'app',
        component: () => import('../views/app/index'),

    },
    {
        path: '/mobile/app',
        name: 'mobileApp',
        component: () => import('../views/mobile/app'),
        children:
            [
                {
                    path: '/mobile/home',
                    meta: {title: '首页'},
                    name: 'home',
                    component: () => import('../views/mobile/home.vue'),
                },
                {
                    path: '/mobile/navigation',
                    meta: {title: '导航'},
                    name: 'navigation',
                    component: () => import('../views/mobile/navigation.vue'),
                },
                {
                    path: '/mobile/mine',
                    name: 'mine',
                    meta: {title: '我的'},
                    component: () => import('../views/mobile/mine.vue')
                },

            ]
    },
    {
        path: '/mobile/add',
        name: 'add',
        component: () => import('../views/mobile/add'),
    },
    {
        path: '/mobile/equipmentLogin',
        name: 'equipmentLogin',
        component: () => import('../views/mobile/equipmentLogin'),
    },
    {
        path: '/mobile/list',
        name: 'list',
        component: () => import('../views/mobile/list'),
    },
    {
        path: '/equipment/play',
        name:'equipmentPlay',
        component: () => import('../views/equipment/play'),
    },
    {
        path: '/qrCodeScanner',
        name:'qrCodeScanner',
        component: () => import('../views/mobile/qrCodeScanner'),
    },


]


export default new Router({
    mode: 'hash', //'hash',//history
    routes
});
