import Vue from 'vue';
import App from './App.vue';
import router from './router';
import constants from './assets/api/constants';
//productionTip设置为 false ，可以阻止 vue 在启动时生成生产提示
// 开发环境下，Vue 会提供很多警告来帮你对付常见的错误与陷阱。而在生产环境下，这些警告语句却没有用，反而会增加应用的体积。
// 此外，有些警告检查还有一些小的运行时开销，这在生产环境模式下是可以避免的
Vue.config.productionTip = false;

//图表插件
import eCharts from 'echarts';
Vue.prototype.echarts = eCharts;

//Element组件
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element, {
  size: 'medium' // set element-ui default size
})
import './assets/css/index.css' // 全局样式
import './assets/css/iconfont.css' // 全局样式
import './assets/js/iconfont.js'
// 在main.js中全局引入
import VideoPlayer from 'vue-video-player'
//引入样式
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'

Vue.use(VideoPlayer);

//import "default-passive-events"; //添加事件管理者'passive'，来阻止'touchstart'事件，让页面更加流畅。 解决chrome下的warning问题

import Contextmenu from "vue-contextmenujs"
Vue.use(Contextmenu);


//按需引入vant
import {
  Button,
  Tabbar,
  TabbarItem,
  Field,
  Icon,
  Picker,
  Popup,
  Collapse,
  CollapseItem,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Notify,
  Toast,
  Dialog,
  Step,
  Steps,
  DatetimePicker,
  Col,
  Row,
  Cell,
  CellGroup,
  Uploader,
  Tab,
  Tabs,
  Swipe,
  SwipeItem,
  IndexBar,
  IndexAnchor,
  Progress,
  Overlay,
  Image,
  Lazyload,
  ActionSheet,
  DropdownMenu,
  DropdownItem,
  Form,
  List,
  PullRefresh,
  Calendar,
  Cascader,
  ImagePreview,
  Loading,
  Search,
  Circle,
  Area,
  Rate,
  Popover,
  NumberKeyboard,
  Switch,NoticeBar
} from 'vant';
import 'vant/lib/index.css';
Vue.use(Button)
    .use(Tabbar)
    .use(TabbarItem).use(Field).use(Icon).use(Picker)
    .use(Popup).use(Collapse).use(CollapseItem)
    .use(RadioGroup).use(Radio).use(Step).use(Steps)
    .use(Checkbox).use(CheckboxGroup).use(Toast).use(Dialog).use(Notify).use(DatetimePicker)
    .use(Col).use(Row).use(Cell).use(CellGroup).use(Uploader).use(Tab).use(Tabs).use(Swipe)
    .use(SwipeItem).use(IndexBar).use(IndexAnchor).use(Progress).use(Overlay).use(Image)
    .use(Lazyload).use(ActionSheet).use(DropdownMenu).use(Popover).use(NumberKeyboard)
    .use(DropdownItem).use(Form).use(List).use(PullRefresh).use(Calendar).use(Cascader).use(ImagePreview)
    .use(Loading).use(Search).use(Circle).use(Area).use(Rate).use(Switch).use(NoticeBar)

Vue.prototype.$ImagePreview = ImagePreview;
Vue.prototype.$mobilaAlert =  function(text){
      Dialog.alert({
        title: '提示',
        message:text
      });
};//Toast;
Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;


Vue.prototype.$alert= (msg,type)=> {
    Element.Notification({
      title: '温馨提示',
      message: msg,
      dangerouslyUseHTMLString: true,
      type: type?type:'success',////"success","warning"
      position: 'bottom-right',
      duration:  4000
    });
};
Vue.prototype.$constants= constants;
Vue.prototype.$isMobile= ()=> {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag
}



Vue.directive('fullScreen', {
  bind: function (el, binding, vnode) {
    let isFullScreen = false
    const dialog = el.querySelector('.el-dialog')
    const header = el.querySelector('.el-dialog__headerbtn')
    if (!header || !dialog || !vnode) return;
    const fullScreenBtn = document.createElement('button')
    fullScreenBtn.type = 'button'
    fullScreenBtn.style = 'background: 0 0;border:0;outline:0;cursor:pointer;font-size:16px;color: #fff';
    const fullScreenIcon = document.createElement('i')
    fullScreenIcon.className = 'el-icon el-icon-full-screen'
    fullScreenBtn.append(fullScreenIcon);
    dialog.style.oldWidth = dialog.style.width;
    dialog.style.oldHeight = dialog.style.height;
    fullScreenBtn.addEventListener('click', () => {
      isFullScreen = !isFullScreen;
      if (isFullScreen) {
        dialog.style.width = '100%';
        dialog.style.height = '100%';
      } else {
        dialog.style.width = dialog.style.oldWidth;
        dialog.style.height =  dialog.style.oldHeight;
      }
      return false
    },{ passive: false });
    header.parentNode.insertBefore(fullScreenBtn, header);
    //header.append(fullScreenBtn)
    dialog.addEventListener('dblclick', () => {
      isFullScreen = !isFullScreen
      if (isFullScreen) {
        dialog.style.width = '100%'
        dialog.style.height = '100%'
      } else {
        dialog.style.width = dialog.style.oldWidth;
        dialog.style.height =  dialog.style.oldHeight;
      }
      return false
    },{ passive: false })
    // event.preventDefault()是 取消事件的默认动作
    // passive: false 是阻止默认行为， 为 true时 event.preventDefault()不产生作用，即不阻止默认行为
  }
})




Vue.directive('dbClickView', {
  bind: function (el) {

    el.addEventListener('dblclick',(event)=>{
            event.stopPropagation();// 阻止事件冒泡
            let parentDiv=document.createElement("div");
            parentDiv.style.backgroundColor="#000000";
            parentDiv.className='openAnim';
            parentDiv.id= 'fullViewDiv';
            parentDiv.style.width='100%';
            parentDiv.style.height='100%';
            parentDiv.style.position='fixed';
            parentDiv.style.top='0';
            parentDiv.style.zIndex='9999';
            parentDiv.innerHTML=el.innerHTML;
            const iframe = parentDiv.querySelector('iframe');
            if(iframe){
              iframe.style.pointerEvents = 'none';
              iframe.style.transform = 'scale(1)';
              iframe.style.visibility='visible';
            }
            document.body.appendChild(parentDiv);
            parentDiv.addEventListener('dblclick',()=>{
              parentDiv.className='closeAnim';
              window.setTimeout(()=>{
                document.body.removeChild(parentDiv);
              },300)
            })

    })
  }
});

new Vue({
  render: h => h(App),
  router,
}).$mount('#app');
