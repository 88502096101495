<template>
  <div class="app" >
     <keep-alive>
        <router-view ></router-view>
     </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {

  },
  data(){
    return{
      key:0,

    }
  },
  mounted(){

  },
  methods: {

  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.app{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
</style>
